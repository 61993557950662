<template>
  <main class="navbar-offset-tablet salon-info-background full-page-height">
    <PageNavigator :page-title="$t('salon-bookings.page-title')" :has-back="false" />
    <div class="form-group row pt-7-5 px-2">
      <div class="col-3">
        <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">
          {{ $t('salon-bookings.input-label.filters') }}
        </label>
        <Multiselect
          v-model="appliedFilter"
          :options="filters"
          :reduce="filter => filter.value"
          label="label"
          :searchable="false"
          :select-label="''"
          :hide-selected="false"
          :allow-empty="false"
          placeholder="Specialist"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ $t(props.option.label) }}</span>
            </span>
          </template>
          <template slot="caret" slot-scope="{ option, toggle }">
            <div
              class="multiselect__select d-flex align-items-center justify-content-center"
              @mousedown.prevent.stop="toggle()"
            >
              <font-awesome-icon :icon="['far', 'angle-down']"></font-awesome-icon>
            </div>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ $t(props.option.label) }}</span>
            </div>
          </template>
        </Multiselect>
      </div>
      <div v-if="showDatepickerCondition" class="col-3">
        <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">
          {{ $t('salon-bookings.input-label.from_date') }}
        </label>
        <vc-date-picker
          ref="calendar"
          v-model="fromDate"
          :max-date="now"
          :locale="siteLang === 'null' || !siteLang ? 'en' : siteLang"
          class="date-picker"
          :popover="{ placement: 'bottom', visibility: 'click' }"
          :attributes="calendarAttrs"
          color="orange"
          :rows="1"
          :nav-visibility="'hidden'"
          :pane-width="270"
          is-expanded
        >
          <a slot="header-left-button">
            <font-awesome-icon :icon="['far', 'angle-left']" class="" style="font-size: 18px;"></font-awesome-icon>
          </a>
          <a slot="header-right-button">
            <font-awesome-icon :icon="['far', 'angle-right']" class="" style="font-size: 18px;"></font-awesome-icon>
          </a>
        </vc-date-picker>
      </div>
      <div v-if="showDatepickerCondition" class="col-3">
        <label class="poppins-font text-black mb-0-5 font-weight-medium text-small">
          {{ $t('salon-bookings.input-label.to_date') }}
        </label>
        <vc-date-picker
          ref="calendar"
          v-model="toDate"
          :min-date="fromDate"
          :max-date="now"
          :locale="siteLang === 'null' || !siteLang ? 'en' : siteLang"
          class="date-picker"
          :popover="{ placement: 'bottom', visibility: 'click' }"
          :attributes="calendarAttrs"
          color="orange"
          :rows="1"
          :nav-visibility="'hidden'"
          :pane-width="270"
          is-expanded
        >
          <a slot="header-left-button">
            <font-awesome-icon :icon="['far', 'angle-left']" class="" style="font-size: 18px;"></font-awesome-icon>
          </a>
          <a slot="header-right-button">
            <font-awesome-icon :icon="['far', 'angle-right']" class="" style="font-size: 18px;"></font-awesome-icon>
          </a>
        </vc-date-picker>
      </div>
      <div class="col-md-auto">
        <div class="mt-3 d-flex flex-wrap text-nowrap">
          <span class="text-black mb-0">
            {{ $t('salon-bookings.input-label.apply_filter') }}
          </span>
          <div v-show="!filtersApplied" class="checkbox-apply ml-1" @click="filtersApplied = true"></div>
          <font-awesome-icon
            v-show="filtersApplied"
            :icon="['fas', 'check-circle']"
            class="checkbox-apply checkbox-applied ml-1"
            @click="filtersApplied = false"
          >
          </font-awesome-icon>
        </div>
      </div>
    </div>
    <div v-if="!isHistoryLoading" class="px-1-5 pt-5-5 salon-flow">
      <BookingsHistoryTable table-type="salonOrders" :items="salonBookingsHistory.data" @change-sort="changeSort" />
    </div>
    <div v-if="!isHistoryLoading" class="px-1-5 pt-5-5 salon-flow">
      <CustomPagination
        :data="salonBookingsHistory"
        :show-disabled="true"
        @pagination-change-page="changePaginationPage"
      ></CustomPagination>
    </div>
  </main>
</template>

<script>
import PageNavigator from '@/components/PageNavigator'
import BookingsHistoryTable from '@/components/salon/BookingsHistoryTable'
import CustomPagination from '@/components/CustomPagination'

import Multiselect from 'vue-multiselect'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
export default {
  name: 'SalonBookingsHistory',
  components: { PageNavigator, BookingsHistoryTable, Multiselect, CustomPagination },
  data() {
    return {
      now: moment().format('YYYY-MM-DD'),
      isHistoryLoading: false,
      appliedFilter: {
        value: 'all_filters',
        label: 'salon-bookings.filters.all_filters'
      },
      page: 1,
      filtersApplied: true,
      sortValue: '-start_time',
      filters: [
        {
          label: 'salon-bookings.filters.all_filters',
          value: 'all_filters'
        },
        {
          label: 'salon-bookings.filters.choose_period',
          value: 'choose_period'
        }
      ],
      fromDate: '',
      toDate: '',
      calendarAttrs: [
        {
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      salonBookingsHistory: 'payments/getSalonBookingsHistoryList',
      accountInfo: 'user/getAccountInfo',
      siteLang: 'user/getSiteLang'
    }),
    salonId() {
      return this.accountInfo.salon_id
    },
    dateParams() {
      if (this.fromDate && this.toDate) {
        const fromDate = moment(this.fromDate).format('YYYY-MM-DD HH:mm:ss')
        const toDate = moment(this.toDate).format('YYYY-MM-DD HH:mm:ss')
        return { start_day: fromDate, end_day: toDate }
      } else {
        return {}
      }
    },
    showDatepickerCondition() {
      return this.appliedFilter.value === 'choose_period'
    }
  },
  watch: {
    'appliedFilter.value': {
      handler(newVal, oldVal) {
        if (oldVal !== newVal) {
          this.fromDate = null
          this.toDate = null
        }
      }
    },
    fromDate(newVal, oldVal) {
      if (newVal && this.toDate) {
        let fromDate = moment(newVal)
        let toDate = moment(this.toDate)

        if (fromDate.diff(toDate, 'days') > 0) {
          this.toDate = null
        }
      }

      if (oldVal !== newVal) this.filtersApplied = false
    },
    toDate(newVal, oldVal) {
      if (oldVal !== newVal) this.filtersApplied = false
    },
    filtersApplied(newVal) {
      if (newVal) this.applyFilters()
    },
    appliedFilter(newVal) {
      if (newVal.value && newVal.value === 'all_filters') {
        this.filtersApplied = true
      } else {
        this.filtersApplied = false
      }
    }
  },
  mounted() {
    this.isHistoryLoading = true
    this.getSortedBookingsHistory({ salon_id: this.salonId, sort: this.sortValue, page: this.page, ...this.dateParams })
  },
  methods: {
    ...mapActions({
      getSalonBookingsHistory: 'payments/getBookingsHistory',
      setError: 'userMessages/setError'
    }),
    getSortedBookingsHistory(params) {
      return this.getSalonBookingsHistory(params)
        .then(() => (this.isHistoryLoading = false))
        .catch(() => {
          this.setError({
            type: 'get_sorted_bookings_error',
            messageI18Key: 'common.alerts.actions.global_error'
          })
          this.isHistoryLoading = false
        })
    },
    changePaginationPage(page) {
      this.page = page
      this.getSortedBookingsHistory({ salon_id: this.salonId, sort: this.sortValue, page })
    },

    applyFilters() {
      const sortValue = { sort: this.sortValue }
      const isChooseDateFilterValid = (!this.fromDate && this.toDate) || (this.fromDate && !this.toDate)

      if (isChooseDateFilterValid) {
        this.filtersApplied = false

        this.setError({
          type: 'empty_date_fields',
          messageI18Key: 'salon-bookings.errors.empty_date_fields'
        })
        return
      }

      if (this.filtersApplied && this.appliedFilter.value === 'choose_period') {
        let paymentsReportsPayload = {
          sort: this.sortValue,
          page: this.page,
          salon_id: this.salonId,
          ...this.dateParams
        }
        this.getSortedBookingsHistory(paymentsReportsPayload)
      } else if (this.filtersApplied && this.appliedFilter.value === 'all_filters') {
        this.getSortedBookingsHistory({ salon_id: this.salonId, ...sortValue, page: this.page })
      }
    },
    changeSort(newParams) {
      this.getSortedBookingsHistory({
        salon_id: this.salonId,
        page: this.page,
        ...newParams
      })
      this.sortValue = newParams.sort
    }
  }
}
</script>

<style lang="scss" scoped>
.multiselect {
  color: #000;
}
.date-picker ::v-deep input {
  border-color: #e0e0e0;
}
.checkbox-apply {
  border-radius: 50%;
  border: 0.5px solid #e0e0e0;
  width: 18px;
  height: 18px;
  margin-top: 3px !important;
  &:hover {
    cursor: pointer;
  }
  &.checkbox-applied {
    color: #ff6b09;
    border: none;
  }
}
</style>
